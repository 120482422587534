<!--c系统退货退款单管理-->
<template>
    <div class="SystemcRefundDelivery">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="5">
                        <el-form-item label="订单编码">
                            <el-input placeholder="" v-model="form.refundCode" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never">
            <el-button type="primary" @click="handleQuery" size="small">查询 </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="售后单号" width="80" prop="refundNo" v-if="showColumn('refundNo')" />
                <el-table-column
                    label="订单编码"
                    min-width="80"
                    prop="orderBizCode"
                    v-if="showColumn('orderBizCode')"
                />
                <el-table-column label="订单号" min-width="80" prop="orderNo" v-if="showColumn('orderNo')" />
                <el-table-column
                    label="部门名称"
                    min-width="80"
                    prop="orderReceiveDeptName"
                    v-if="showColumn('orderReceiveDeptName')"
                />
                <el-table-column label="会员名称" min-width="80" prop="memberName" v-if="showColumn('memberName')" />
                <el-table-column
                    label="支付类型名称"
                    min-width="80"
                    prop="refundPayTypeName"
                    v-if="showColumn('refundPayTypeName')"
                />

                <el-table-column
                    label="配送人"
                    min-width="80"
                    prop="orderDeliveryerName"
                    v-if="showColumn('orderDeliveryerName')"
                />

                <el-table-column label="创建时间" min-width="100" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="是否生成退货单"
                    width="100"
                    prop="refundTradeCode"
                    v-if="showColumn('refundTradeCode')"
                    key="refundTradeCode"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundTradeCode | refundTradeCode }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="生成退货单时间"
                    width="100"
                    prop="updateTime"
                    v-if="showColumn('refundTradeCode')"
                    key="updateTime"
                >
                    <template slot-scope="scope" v-if="scope.row.refundTradeCode">
                        <span>{{ scope.row.updateTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品退还金额"
                    width="100"
                    prop="goodsRefundMoney"
                    v-if="showColumn('goodsRefundMoney')"
                    key="goodsRefundMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.goodsRefundMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退还金额"
                    width="100"
                    prop="refundMoney"
                    v-if="showColumn('refundMoney')"
                    key="refundMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundMoney | money }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="操作"
                    min-width="90"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';

export default {
    name: 'SystemcRefundDelivery',
    components: {
        EfDeptTypeAndDeptSelect,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            that: this,
            tableData: [],
            refundCodeList: [],
            form: {
                refundCode: '',
                deptCode: '',
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                payTypeAll: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            url: {
                createRefundTrade: '/fromSystemc/refund/createRefundTrade',
                queryPayType: '/finance/pay/payType/page',
                page: '/fromSystemc/refund/queryOrderRefundList',
            },
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        refundTradeCode(refundTradeCode) {
            if (refundTradeCode) {
                return '已创建';
            }
            if (Util.isEmpty(refundTradeCode)) {
                return '未创建';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        setDept(deptCode) {
            this.form.deptCode = deptCode;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        rowDetail(row) {
            Util.nameJump(
                this,
                'menu.sale.systemcRefundDelivery.detail',
                ['销售管理', 'c系统退款退货单', 'c系统退款退货单详情'],
                { form: row }
            );
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
    },
};
</script>

<style scoped>
.Order .el-form-item {
    margin-bottom: 0;
}
</style>
